export interface LeadershipPosition {
  organization: string;
  date: string;
  role: string;
  description: string;
}

const leadershipData: LeadershipPosition[] = [
  {
    organization: "Princeton Impact Capital",
    date: "10/2022 - present",
    role: "Member",
    description:
      "Collaborated with partners on weekly market updates and participated in a training bootcamp that focused on socially responsible investing and other financial education.",
  },
  {
    organization: "Global Stand Against COVID",
    date: "2020 - 2021",
    role: "Student Questionnaire Committee Leader",
    description:
      "Worked with fellow students to raise thousands of dollars for covid relief, compiled a mental health questionnaire for use in a Q&A session with a medical professional, and co-produced mental health awareness videos.",
  },
  {
    organization: "Heroes & Cool Kids",
    date: "2019 - 2021",
    role: "Volunteer Mentor",
    description:
      "Visited middle schoolers to address topics like alcohol, bullying, and adjusting to high school.",
  },
  {
    organization: "High School Baseball",
    date: "2017 - 2021",
    role: "Captain",
    description: "Led my team as captain for both my junior and senior years.",
  },
  {
    organization: "High School Football",
    date: "2017 - 2021",
    role: "Captain",
    description:
      "Led my team as captain for my senior year and led the defense as the middle linebacker.",
  },
];

export default leadershipData;
