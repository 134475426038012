export type Honors = string[];

export interface School {
  name: string;
  cumulativeGPA: string;
  departmentalGPA: string;
  attended: string;
  degree?: string;
  honors: Honors;
}

const schoolData: School[] = [
  {
    name: "Princeton University",
    cumulativeGPA: "3.89 / 4.00",
    departmentalGPA: "4.00 / 4.00",
    attended: "2021 - 2025",
    degree:
      "Operations Research and Financial Engineering B.S.E. with certificates in statistics, machine learning, and applied mathematics.",
    honors: [
      "A+ in ECO100 (requires professor to submit written statement of justification).",
    ],
  },
  {
    name: "JFK Memorial High School",
    cumulativeGPA: "4.33 / 4.33",
    departmentalGPA: "",
    attended: "2017 - 2021",
    honors: [
      "National Merit Scholarship Recipient (2021)",
      "Ranked 4 / 333 (2021)",
      "Class President (2017-2021)",
      "NJSIAA Scholar Athlete (2021)",
      "AP Scholar with Distinction (2021)",
      "Billy Denny Scholar Athlete (2021)",
      "National Honor Society (2020-2021)",
      "Boys State Representative (2020)",
    ],
  },
];

export default schoolData;
