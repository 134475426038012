export interface Skill {
  name: string;
}

export interface SkillsSection {
  name: string;
  skills: Skill[];
}

const programming: Skill[] = [
  { name: "Python" },
  { name: "Java" },
  { name: "R" },
  { name: "NumPy" },
  { name: "Pandas" },
  { name: "Git / GitHub" },
  { name: "RStudio" },
  { name: "IntelliJ" },
  { name: "Visual Studio Code" },
];

const productivity: Skill[] = [
  { name: "Microsoft Excel" },
  { name: "Microsoft PowerPoint" },
  { name: "Google Drive" },
  { name: "Slack" },
];

const miscellaneous: Skill[] = [{ name: "djay Pro AI" }, { name: "iMovie" }];

const skillsData: SkillsSection[] = [
  {
    name: "Programming",
    skills: programming,
  },
  {
    name: "Productivity",
    skills: productivity,
  },
  {
    name: "Miscellaneous",
    skills: miscellaneous,
  },
];

export default skillsData;
