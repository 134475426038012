import MichaelHein from "../pages/michael-hein/MichaelHein";

interface Route {
  path: string;
  exact: boolean;
  component: () => JSX.Element;
}
const routes: Route[] = [{ path: "/", exact: false, component: MichaelHein }];

export default routes;
