import { createTheme as createMuiTheme, Theme } from "@material-ui/core/styles";
import "./Lato.css";

export enum Color {
  light = "light",
  gray = "gray",
  primary = "primary",
  secondary = "secondary",
}

declare module "@material-ui/core/styles/createTheme" {
  interface Theme {
    customPalette: {
      light: string;
      gray: string;
      primary: string;
      secondary: string;
    };
  }
  interface ThemeOptions {
    customPalette?: {
      light?: string;
      gray?: string;
      primary?: string;
      secondary?: string;
    };
  }
}

const themes: { [key: string]: Theme } = {
  "michael-hein": createMuiTheme({
    customPalette: {
      light: "#FFF",
      gray: "#F8F8F8",
      primary: "#1E4540",
      secondary: "#C7B000",
    },
  }),
};

export default themes;