import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MichaelTHeinState {
  
}

const initialState: MichaelTHeinState = {
  
};

export const michaelTHeinSlice = createSlice({
  name: "michael-t-hein",
  initialState,
  reducers: {
  },
});

export const {
  
} = michaelTHeinSlice.actions;
export default michaelTHeinSlice.reducer;