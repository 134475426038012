export interface introduction {
  tagline: string;
  body: string;
  outro: string;
}

const introductionData: introduction = {
  tagline: "",
  body: "I am a sophomore at Princeton University studying ORFE – the intersection of financial engineering and computer science. I am also working towards certificates in statistics, machine learning, and applied mathematics. Outside of the classroom, I am a member of Princeton Impact Capital and the club baseball team. I have interned for Alariss Global, a boutique recruiting startup that specializes in connecting foreign companies with U.S. sales workers. In my free time, I develop personal projects primarily using Python and I remix songs.",
  outro: "",
};

export default introductionData;
