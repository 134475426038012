export interface projectExperience {
  name: string;
  date: string;
  tools: string[];
  descriptions: string[];
}

const projectData: projectExperience[] = [
  {
    name: "Dice Game Bot",
    date: "8 / 2022",
    tools: ["Python", "NumPy", "Pandas", "PyTorch", "VS Code"],
    descriptions: [
      "Implemented statistical dice game in Python.",
      "Created automated bots with various play strategies.",
      "Provided a player interface that allows other developers to create their own bots.",
      "Trained a neural network to predict other players private information in PyTorch.",
    ],
  },
  {
    name: "Firework Simulator",
    date: "4 / 2022",
    tools: ["Java", "IntelliJ"],
    descriptions: [
      "Built Java program which uses graphics library to simulate a fireworks show.",
      "Created a user interface to choose between different modes.",
      "Program reads input file to determine color, direction, and velocity of each firework.",
    ],
  },
  {
    name: "Independent Research Project",
    date: "12 / 2021",
    tools: [
      "Microsoft Excel",
      "Microsoft PowerPoint",
      "Correlation",
      "Regression",
    ],
    descriptions: [
      "Studied correlation between population growth rates and the rate of improvement in the standard of living.",
      "Calculated correlation and regression statistics using Microsoft Excel.",
      "Delivered a visual presentation to peers and university professors.",
    ],
  },
];

export default projectData;
