export interface WorkExperience {
  organization: string;
  date: string;
  role: string;
  tools: string[];
  descriptions: string[];
}

const workData: WorkExperience[] = [
  {
    organization: "Alariss Global",
    date: "6/2022 - 8/2022",
    role: "Product Manager Intern",
    tools: [
      "Python",
      "Microsoft Excel",
      "Microsoft Word",
      "Microsoft PowerPoint",
    ],
    descriptions: [
      "Researched and developed strategies for sales and recruiting operations.",
      "Reviewed company code and completed personal Python coding projects.",
      "Met with co-workers to identify pain points and develop new product features for applicant tracking system.",
      "Managed company-wide outreach efforts using Microsoft Excel.",
      "Gained experience working at a startup engaged in international business.",
    ],
  },
  {
    organization: "Springwood Swim Club",
    date: "6/2018 - 9/2018",
    role: "Lifeguard",
    tools: ["CPR Certified", "First Aid Certified"],
    descriptions: [
      "Oversaw safety and satisfaction of more than 150 patrons per day.",
      "Coordinated events for private parties.",
    ],
  },
];

export default workData;
