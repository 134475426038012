export interface NavigationSection {
  name: string;
  slug: string;
}

export interface NavigationMember {
  name: "Michael Hein";
  slug: "michael-hein";
  navigation: NavigationSection[];
}

const michaelHeinLabels: NavigationSection[] = [
  {
    name: "Education",
    slug: "education",
  },
  {
    name: "Skills",
    slug: "skills",
  },
  {
    name: "Leadership",
    slug: "leadership",
  },
  {
    name: "Work",
    slug: "work",
  },
  {
    name: "Projects",
    slug: "projects",
  },
  {
    name: "Contact",
    slug: "contact",
  },
  {
    name: "More",
    slug: "more",
  },
];

const navigationData: NavigationMember[] = [
  {
    name: "Michael Hein",
    slug: "michael-hein",
    navigation: michaelHeinLabels,
  },
];

export default navigationData;
