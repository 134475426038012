export interface WorkExperience {
  category: string;
  bullets: string[];
}

const workData: WorkExperience[] = [
  {
    category: "Fun Facts",
    bullets: [
      "I do the worm at every dance party.",
      "I view any all-you-can-eat buffet as a challenge to eat more than my money's worth.",
      "I prefer Diet Coke over Coca-Cola.",
      "I am the self-proclaimed originator of the next best thing in pizza: the crust lover's pie.",
      "I memorized an entire song in Spanish without knowing how to speak any Spanish.",
      'I created my own dance remix to Andy Grammer\'s "Fresh Eyes".',
      "I annually win the award for slowest driver among all my friends.",
      "I have seen every New Girl and How I Met Your Mother episode at least three times.",
      'I love the movie "The Wedding Singer".',
    ],
  },
];

export default workData;
