import React from "react";

import {
  Theme,
  Typography,
  Button,
  Link,
  Dialog,
  DialogTitle,
} from "@material-ui/core";

import DescriptionIcon from "@material-ui/icons/Description";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import SchoolIcon from "@material-ui/icons/School";
import CodeIcon from "@material-ui/icons/Code";
import PeopleIcon from "@material-ui/icons/People";
import GitHubIcon from "@material-ui/icons/GitHub";
import EmailIcon from "@material-ui/icons/Email";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import themes, { Color } from "../../config/themes";

import {
  load,
  setMember,
  setPage,
  setError,
  setTheme,
} from "../../components/navigation/navigationSlice";

import Section from "../../components/section/Section";
import SectionHeader from "../../components/section-header/SectionHeader";
import Flashlight from "../../components/flashlight/Flashlight";

import introductionData from "./introductionData";
import schoolData from "./schoolData";
import skillsData from "./skillsData";
import leadershipData from "./leadershipData";
import workData from "./workData";
import projectData from "./projectData";
import moreData from "./moreData";

import { useAppDispatch, useAppSelector } from "../../state/hooks";
import useStyles from "./style";

export default function MichaelHein(): JSX.Element {
  const [openCellDialog, setOpenCellDialog] = React.useState(false);

  const dispatch = useAppDispatch();
  const activeMember = useAppSelector((state) => state.navigation.activeMember);
  const activeTheme = useAppSelector((state) => state.navigation.activeTheme);
  dispatch(setMember("Michael Hein"));
  dispatch(setPage("michael-hein"));
  dispatch(setTheme("michael-hein"));

  dispatch(load());

  const width = useAppSelector((state) => state.app.width);

  const theme: Theme = themes[activeTheme];
  const classes = useStyles();

  return (
    <>
      <div style={{ cursor: "default", paddingTop: "3.25rem" }}>
        <Section
          color={Color.primary}
          content={
            <div>
              <Typography className={`${classes.body} ${classes.gray}`}>
                Welcome to a webpage about...
              </Typography>
              {activeMember.split("").map((c) => {
                return c === " " ? (
                  <Typography
                    className={classes.title}
                    style={{ display: "inline" }}
                  >
                    &nbsp;
                  </Typography>
                ) : (
                  <Typography
                    className={classes.title}
                    style={{ display: "inline" }}
                  >
                    <Flashlight color={Color.gray} content={c} />
                  </Typography>
                );
              })}
            </div>
          }
          skinny={false}
        />
      </div>
      <Section
        color={Color.gray}
        content={
          <>
            <SectionHeader
              color={Color.primary}
              id="intro"
              content={<>Introduction</>}
            />
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div
                className={width > 800 ? classes.eight : classes.twelve}
                style={{ marginRight: "auto" }}
              >
                <Typography className={classes.body}>
                  {introductionData.body}
                </Typography>
                <div style={{ display: "flex" }}>
                  <Button
                    className={`${classes.backgroundSecondary} ${classes.btn}`}
                    href="https://michael-hein-web-assets.s3.amazonaws.com/documents/resume.pdf"
                    target="_blank"
                  >
                    <Typography
                      className={`${classes.btnText} ${classes.light}`}
                    >
                      Resume
                    </Typography>
                    <DescriptionIcon
                      style={{ marginLeft: "0.25rem" }}
                      className={classes.light}
                    />
                  </Button>
                </div>
              </div>
              {width > 800 ? (
                <div className={classes.three}>
                  <img
                    className={`${classes.image} ${classes.borderSecondary}`}
                    style={{ width: "100%" }}
                    src="https://michael-hein-web-assets.s3.amazonaws.com/images/profile.jpg"
                    alt="Profile Picture"
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </>
        }
        skinny={false}
      />
      <Section
        color={Color.primary}
        content={
          <>
            <SectionHeader
              color={Color.gray}
              id="education"
              content={
                <>
                  Education
                  <SchoolIcon
                    className={`${classes.gray} ${classes.sectionHeaderIcon}`}
                  />
                </>
              }
            />
            <div
              style={{
                display: "flex",
                flexDirection: width > 1100 ? "row" : "column",
                justifyContent: "flex-start",
              }}
            >
              {schoolData.map((school) => {
                return (
                  <div
                    className={
                      width > 1110
                        ? school.name === "Princeton University"
                          ? classes.six
                          : classes.five
                        : classes.twelve
                    }
                    style={
                      school.name === "Princeton University"
                        ? {
                            marginRight: "auto",
                            marginBottom: width > 1110 ? "0rem" : "3rem",
                          }
                        : {}
                    }
                  >
                    <Typography
                      className={`${classes.subtitle} ${
                        school.name === "Princeton University"
                          ? classes.princeton
                          : classes.kennedy
                      }`}
                    >
                      {school.name}
                    </Typography>
                    <Typography className={`${classes.body} ${classes.gray}`}>
                      <ul className={classes.list}>
                        <li>
                          <span
                            className={
                              school.name === "Princeton University"
                                ? classes.princeton
                                : classes.kennedy
                            }
                            style={{ fontWeight: 400 }}
                          >
                            Cumulative GPA:&nbsp;
                          </span>
                          <span>{school.cumulativeGPA}</span>
                        </li>
                        {school.departmentalGPA.length > 0 ? (
                          <li>
                            <span
                              className={
                                school.name === "Princeton University"
                                  ? classes.princeton
                                  : classes.kennedy
                              }
                              style={{ fontWeight: 400 }}
                            >
                              Departmental GPA:&nbsp;
                            </span>
                            <span>{school.departmentalGPA}</span>
                          </li>
                        ) : (
                          <></>
                        )}
                        <li>
                          <span
                            className={
                              school.name === "Princeton University"
                                ? classes.princeton
                                : classes.kennedy
                            }
                            style={{ fontWeight: 400 }}
                          >
                            Attended:&nbsp;
                          </span>
                          <span>{school.attended}</span>
                        </li>
                        {school.degree ? (
                          <li>
                            <span
                              className={
                                school.name === "Princeton University"
                                  ? classes.princeton
                                  : classes.kennedy
                              }
                              style={{ fontWeight: 400 }}
                            >
                              Degree:&nbsp;
                            </span>
                            <span>{school.degree}</span>
                          </li>
                        ) : (
                          <></>
                        )}
                        {school.honors.length > 0 ? (
                          <li>
                            <span
                              className={
                                school.name === "Princeton University"
                                  ? classes.princeton
                                  : classes.kennedy
                              }
                              style={{ fontWeight: 400 }}
                            >
                              Honors:
                            </span>
                            <span>
                              <ul>
                                {school.honors.map((honor) => {
                                  return <li>{honor}</li>;
                                })}
                              </ul>
                            </span>
                          </li>
                        ) : (
                          <></>
                        )}
                      </ul>
                    </Typography>
                  </div>
                );
              })}
            </div>
          </>
        }
        skinny={false}
      />
      <Section
        color={Color.gray}
        content={
          <>
            <SectionHeader
              color={Color.primary}
              id="skills"
              content={
                <>
                  Skills
                  <CodeIcon
                    className={`${classes.primary} ${classes.sectionHeaderIcon}`}
                  />
                </>
              }
            />

            {skillsData.map((skillSection) => {
              return (
                <>
                  <Typography
                    className={`${classes.subtitle} ${classes.primary}`}
                  >
                    {skillSection.name}
                  </Typography>
                  <Typography
                    className={`${classes.body} ${classes.primary}`}
                    style={{ marginBottom: "3rem" }}
                  >
                    {skillSection.skills[0].name}
                    {skillSection.skills.slice(1).map((skill) => (
                      <> &bull; {skill.name}</>
                    ))}
                  </Typography>
                </>
              );
            })}
          </>
        }
        skinny={false}
      />
      <Section
        color={Color.light}
        content={
          <>
            <SectionHeader
              color={Color.primary}
              id="leadership"
              content={
                <>
                  Leadership
                  <PeopleIcon
                    className={`${classes.primary} ${classes.sectionHeaderIcon}`}
                  />
                </>
              }
            />

            {leadershipData.map((position) => {
              return (
                <>
                  <Typography
                    className={`${classes.subtitle} ${classes.primary}`}
                  >
                    {position.organization} ({position.date})
                  </Typography>
                  <Typography
                    className={`${classes.body} ${classes.primary}`}
                    style={{ marginBottom: "3rem" }}
                  >
                    {position.description}
                  </Typography>
                </>
              );
            })}
          </>
        }
        skinny={false}
      />
      <Section
        color={Color.gray}
        content={
          <>
            <SectionHeader
              color={Color.primary}
              id="work"
              content={
                <>
                  Work Experience
                  <BusinessCenterIcon
                    className={`${classes.primary} ${classes.sectionHeaderIcon}`}
                  />
                </>
              }
            />
            {workData.map((workExperience) => {
              return (
                <>
                  <Typography
                    className={`${classes.subtitle} ${classes.primary}`}
                  >
                    {workExperience.organization} ({workExperience.date})
                  </Typography>

                  <Typography
                    className={`${classes.body} ${classes.primary}`}
                    style={{ marginBottom: "0.75rem" }}
                  >
                    {workExperience.tools[0]}
                    {workExperience.tools.slice(1).map((tool) => (
                      <> &bull; {tool}</>
                    ))}
                  </Typography>

                  <Typography
                    className={`${classes.body} ${classes.primary}`}
                    style={{
                      marginBottom: "0.5rem",
                      fontWeight: 400,
                      textDecoration: "underline",
                    }}
                  >
                    {workExperience.role}
                  </Typography>

                  <Typography
                    className={`${classes.body} ${classes.primary}`}
                    style={{ marginBottom: "3rem" }}
                  >
                    <ul>
                      {workExperience.descriptions.map((description) => {
                        return <li>{description}</li>;
                      })}
                    </ul>
                  </Typography>
                </>
              );
            })}
          </>
        }
        skinny={false}
      />
      <Section
        color={Color.light}
        content={
          <>
            <SectionHeader
              color={Color.primary}
              id="projects"
              content={
                <>
                  Projects
                  <Link href="https://github.com/michaelhein39" target="_blank">
                    <GitHubIcon
                      className={`${classes.primary} ${classes.sectionHeaderIcon}`}
                    />
                  </Link>
                </>
              }
            />
            {projectData.map((projectExperience) => {
              return (
                <>
                  <Typography
                    className={`${classes.subtitle} ${classes.primary}`}
                  >
                    {projectExperience.name} ({projectExperience.date})
                  </Typography>

                  <Typography
                    className={`${classes.body} ${classes.primary}`}
                    style={{ marginBottom: "0.75rem" }}
                  >
                    {projectExperience.tools[0]}
                    {projectExperience.tools.slice(1).map((tool) => (
                      <> &bull; {tool}</>
                    ))}
                  </Typography>

                  <Typography
                    className={`${classes.body} ${classes.primary}`}
                    style={{ marginBottom: "3rem" }}
                  >
                    <ul>
                      {projectExperience.descriptions.map((description) => {
                        return <li>{description}</li>;
                      })}
                    </ul>
                  </Typography>
                </>
              );
            })}
          </>
        }
        skinny={false}
      />
      <Section
        color={Color.primary}
        content={
          <>
            <SectionHeader
              color={Color.gray}
              id="contact"
              content={
                <>
                  Contact
                  <EmailIcon
                    className={`${classes.gray} ${classes.sectionHeaderIcon}`}
                  />
                </>
              }
            />
            <Typography className={`${classes.body} ${classes.gray}`}>
              Find me at any of the following:
            </Typography>
            <div style={{ display: "flex" }}>
              <Button
                className={`${classes.backgroundGray} ${classes.btn}`}
                href="mailto:mh6620@princeton.edu"
              >
                {width > 600 ? (
                  <Typography
                    className={`${classes.btnText} ${classes.btnTextShrink} ${classes.primary}`}
                  >
                    Email
                  </Typography>
                ) : (
                  <></>
                )}
                <EmailIcon
                  className={`${classes.primary} ${classes.contactIcon}`}
                />
              </Button>
              <Button
                className={`${classes.backgroundLight} ${classes.btn}`}
                href="https://www.linkedin.com/in/mhein39/"
                target="_blank"
              >
                {width > 600 ? (
                  <Typography
                    className={`${classes.btnText} ${classes.btnTextShrink} ${classes.primary}`}
                  >
                    LinkedIn
                  </Typography>
                ) : (
                  <></>
                )}
                <LinkedInIcon
                  className={`${classes.primary} ${classes.contactIcon}`}
                />
              </Button>
              <Button
                className={`${classes.backgroundLight} ${classes.btn}`}
                href="https://www.github.com/michaelhein39/"
                target="_blank"
              >
                {width > 600 ? (
                  <Typography
                    className={`${classes.btnText} ${classes.btnTextShrink} ${classes.primary}`}
                  >
                    GitHub
                  </Typography>
                ) : (
                  <></>
                )}
                <GitHubIcon
                  className={`${classes.primary} ${classes.contactIcon}`}
                />
              </Button>
            </div>
          </>
        }
        skinny={false}
      />
      <Section
        color={Color.gray}
        content={
          <>
            <SectionHeader
              color={Color.primary}
              id="more"
              content={
                <>
                  More
                  <AudiotrackIcon
                    className={`${classes.primary} ${classes.sectionHeaderIcon}`}
                  />
                </>
              }
            />
            {moreData.map((moreItem) => {
              return (
                <>
                  <Typography
                    className={`${classes.subtitle} ${classes.primary}`}
                  >
                    {moreItem.category}
                  </Typography>

                  <Typography
                    className={`${classes.body} ${classes.primary}`}
                    style={{ marginBottom: "3rem" }}
                  >
                    <ul>
                      {moreItem.bullets.map((bullet) => {
                        return <li>{bullet}</li>;
                      })}
                    </ul>
                  </Typography>
                </>
              );
            })}
          </>
        }
        skinny={false}
      />
    </>
  );
}
